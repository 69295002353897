var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.attendances},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":3}},[_c('h2',[_vm._v(_vm._s(_vm.candidateName)+" részvételei szentmiséken")])]),(_vm.$store.getters.userRole >= _vm.roles.signer)?_c('v-col',{attrs:{"cols":"12","sm":3}},[_c('v-btn',{attrs:{"color":"red accent-4","rounded":""},on:{"click":function($event){return _vm.undo()}}},[_c('v-icon',[_vm._v("fa-undo")]),_vm._v("Visszavonás (20 percen belül)")],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":_vm.$store.getters.userRole >= _vm.roles.signer ? 3 : 4}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Kezdő időpont","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.begin),callback:function ($$v) {_vm.begin=$$v},expression:"begin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenus.begin),callback:function ($$v) {_vm.$set(_vm.dateMenus, "begin", $$v)},expression:"dateMenus.begin"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dateMenus.begin = false}},model:{value:(_vm.begin),callback:function ($$v) {_vm.begin=$$v},expression:"begin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.$store.getters.userRole >= _vm.roles.signer ? 3 : 4}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Befejező időpont","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenus.end),callback:function ($$v) {_vm.$set(_vm.dateMenus, "end", $$v)},expression:"dateMenus.end"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dateMenus.end = false}},model:{value:(_vm.end),callback:function ($$v) {_vm.end=$$v},expression:"end"}})],1)],1)],1)]},proxy:true},{key:"item.signer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Signer.fullname)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((new Date(item.createdAt)).toLocaleString('hu-HU'))+" ")]}},(!(_vm.$store.getters.userRole >= _vm.roles.signer))?{key:"item.reward",fn:function(ref){
var item = ref.item;
return [(item.solutionTime)?_c('span',[_c('v-img',{attrs:{"height":"200","contain":"","src":_vm.rewardImage(item.RewardImageMediumId)}})],1):(new Date(item.createdAt) > _vm.yesterday && item.RewardImageMediumId !== null)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.$router.resolve({ name: 'rewardPuzzle', params: { attendanceId: item.id, rewardImageId: item.RewardImageMediumId } }).href,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-puzzle-piece")])],1)]}}],null,true)},[_vm._v(" Jutalomjáték elkezdése ")])],1):_vm._e()]}}:null],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }